import moment from "moment"
import Cookies from "universal-cookie"
import jwt_decode from "jwt-decode"
import cryptoJs from "crypto-js"
import { cloneDeep } from "lodash"
import _ from "lodash"

const cookies = new Cookies()

export {
  validateSaveInvoiceHeader,
  checkIfTaskIsAssignedToUser,
  ruleValidate,
  hideRule,
  getUserId,
  getQueryObject,
  decryptRequest,
  encryptRequest,
  getQueryObject1,
  checkIFFileIsValid,
  getLocalStorageByKey,
  getRolesByKey,
  checkRule,
  convert,
  objectToUrl,
  clearLocalStorageData,
  setLocalStorageByKey,
  getQueryVariable,
  clearCookiesandLocalStorageData,
  returnNestedValue,
  returnMaskedData,
  recursiveFuncRespAppend,
  sortBySequence,
  retrieveInboxType,
  returnRequestBodyForMicroflow,
  checkRuleForButton,
  setSessionStorageByKey,
  getSessionStorageByKey,
  clearSessionStorage,
  isValidJSON,
  scrollToField
}


function isValidJSON (jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  }
  catch(e) {
    console.log(' JSON Parsing Error!',e)
    return false;
  }
}

function checkRule(rule, invoiceData) {
  let value = rule.value
  if (value && value === "true") {
    value = true
  } else if (value && value === "false") {
    value = false
  }
  if (
    invoiceData &&
    invoiceData[rule.value] &&
    invoiceData[rule.value] != undefined
  ) {
    value = invoiceData[rule.value]
  }
  let invValue = undefined;
  let _index = -1;
  if (rule && rule.refKey != undefined) {
    _index = rule.refKey.indexOf(".");
  }
  if (_index == -1) {

    if (
      invoiceData &&
      invoiceData[rule.refKey] !== undefined &&
      invoiceData[rule.refKey] !== null &&
      invoiceData[rule.refKey] !== ""
    ) {
      invValue = invoiceData[rule.refKey]
    }
  } else {
    invValue = _.get(invoiceData, rule.refKey)
  }

  switch (rule.cond) {
    case "eq":
      // console.log("field.rule.cond", field.key, field.rule.cond, this.props.invoiceHeaderData[field.rule.refKey])
      if (invValue != undefined && invValue == value) {
        return false
        // field['required'] = false
      } else {
        return true
      }
      break
    case "lt":
      // console.log("field.rule.cond", field.key, field.rule.cond, this.props.invoiceHeaderData[field.rule.refKey])
      if (invValue != undefined && invValue < value) {
        return false
        // field['required'] = false
      } else {
        return true
      }
      break
    case "like":
      if (invValue !== undefined && invValue.includes(value)) {
        return false;
      } else {
        return true;
      }
      break;
    case "gt":
      // console.log("field.rule.cond", field.key, field.rule.cond, this.props.invoiceHeaderData[field.rule.refKey])
      if (invValue != undefined && invValue > value) {
        return false
        // field['required'] = false
      } else {
        return true
      }
      break
    case "lte":
      // console.log("field.rule.cond", field.key, field.rule.cond, this.props.invoiceHeaderData[field.rule.refKey])
      if (invValue != undefined && invValue <= value) {
        return false
        // field['required'] = false
      } else {
        return true
      }
      break
    case "gte":
      // console.log("field.rule.cond", field.key, field.rule.cond, this.props.invoiceHeaderData[field.rule.refKey])
      if (invValue != undefined && invValue >= value) {
        return false
        // field['required'] = false
      } else {
        return true
      }
      break
    case "neq":
      // console.log("field.rule.cond.test", field.rule.cond, invValue[field.rule.refKey], field.value, invValue[field.rule.refKey] != field.value)
      if (invValue != undefined && invValue != value) {
        return false
        // field['required'] = false
      } else {
        return true
      }
      break
    case "notEmpty":
      if (
        invValue &&
        invValue != undefined &&
        invValue != null &&
        invValue != ""
      ) {
        return true
        // field['required'] = false
      } else {
        return false
      }
    case "empty":
      if (
        invValue &&
        invValue != undefined &&
        invValue != null &&
        invValue != ""
      ) {
        return false
        // field['required'] = false
      } else {
        return true
      }
      break
    // case "eq-custom":
    //   // console.log("field.rule.cond.test", field.rule.cond, this.state[field.rule.refKey], field.value, this.state[field.rule.refKey] != field.value)
    //   if (this.state[rule.refKey] && this.state[rule.refKey] == value) {
    //     return false
    //     // field['required'] = false
    //   } else {
    //     return true
    //   }
    //   break
    case "date-diff":
      let startDate
      let endDate
      if (invoiceData && invoiceData[rule.refStartDateKey]) {
        startDate = moment(invoiceData[rule.refStartDateKey])
      }
      if (invoiceData && invoiceData[rule.refEndDateKey]) {
        endDate = moment(invoiceData[rule.refEndDateKey])
      }
      if (startDate && endDate) {
        let dateDiff = endDate.diff(startDate, "days")
        value = parseInt(value) || 0
        switch (rule.difference) {
          case "gt":
            if (dateDiff > value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
          case "lt":
            if (dateDiff < value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
          case "eq":
            if (dateDiff == value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
          case "gte":
            if (dateDiff >= value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
          case "lte":
            if (dateDiff <= value) {
              return rule.hide
            } else {
              return !rule.hide
            }
            break
        }
      } else {
        return true
      }

      break

    case "assignUserId":
    case "isnotassignUserId":
      if (invoiceData?.assignmentDetails) {
        const filteredAssignUserId = invoiceData.assignmentDetails.filter(ele =>
          (rule.assignUserId.toString()).includes(ele.userId)
        );
        let ruleStatus = (filteredAssignUserId && filteredAssignUserId.length > 0);
        return (rule.cond == 'assignUserId' ? !ruleStatus : ruleStatus)
      } else {
        return (rule.cond == 'assignUserId' ? false : true)
      }
      break
    case "RO":
    case "displayRoles":
      if (rule.displayRoles && rule.displayRoles.length == 0) {
        return false
      } else if (rule.displayRoles && rule.displayRoles.length > 0) {
        if (invoiceData && invoiceData.assignmentDetails != undefined) {
          // let dispRoles = rule.displayRoles ? rule.displayRoles.split(',') : []
          let filteredRolesPanel = invoiceData.assignmentDetails.filter(ele =>
            rule.displayRoles.includes(ele.stageName)
          )
          if (filteredRolesPanel && filteredRolesPanel.length > 0) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return true
      }
      break
    case "userRolesIncluded":
      const user = JSON.parse(localStorage.getItem('data'));
      const roles = user?.roles || [];
      if (rule.displayRoles && rule.displayRoles.length === 0) {
        return false;
      } else if (rule.displayRoles && rule.displayRoles.length > 0) {
        if (roles && roles !== undefined) {
          const hasUserRoles = roles.some(role => rule.displayRoles.includes(role.roleValue));
          return !hasUserRoles;
        } else {
          return false;
        }
      } else {
        return true;
      }
    case "userRolesExcluded":
      const userdetails = JSON.parse(localStorage.getItem('data'));
      const userRoles = userdetails?.roles || [];
      if (rule.displayRoles && rule.displayRoles.length === 0) {
        return false;
      } else if (rule.displayRoles && rule.displayRoles.length > 0) {
        if (userRoles && userRoles !== undefined) {
          const hasUserRoles = userRoles.some(role => rule.displayRoles.includes(role.roleValue));
          return hasUserRoles;
        } else {
          return true;
        }
      } else {
        return true;
      }

    case "forwardStatus":
      if (rule.forwardStatus) {
        if (invoiceData && invoiceData.assignmentDetails != undefined) {
          // let dispRoles = rule.displayRoles ? rule.displayRoles.split(',') : []
          let fStatus = rule.forwardStatus;
          if (fStatus && fStatus === "true") {
            fStatus = true
          } else if (fStatus && fStatus === "false") {
            fStatus = false
          }
          let filteredRolesPanel = invoiceData.assignmentDetails.filter(ele => {
            return rule.forwardStatusCond === 'neq' ? ele.forwardStatus != fStatus : ele.forwardStatus == fStatus;
          });
          if (filteredRolesPanel && filteredRolesPanel.length > 0) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return true
      }
      break
    case "table-column-gt":
      if (invoiceData != undefined) {
        if (invoiceData.length > 0) {
          let filterData = invoiceData.filter(
            ele => parseFloat(ele[rule.refKey]) > rule.value
          )
          if (filterData.length != invoiceData.length) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }
    case "clientRoles":
      const clientDetails = JSON.parse(localStorage.getItem("clientDetails"));
      if (clientDetails) {
        if (rule.clientRoles) {
          const result = !rule.clientRoles.includes(clientDetails.id.toString());
          return result;
        }
        return true;
      }

      return true;
    case "table-column-empty":
      if (invoiceData != undefined) {
        if (invoiceData.length > 0) {
          let filterData = invoiceData.filter(
            ele => (ele[rule.refKey] != undefined && ele[rule.refKey] != null && ele[rule.refKey] != '')
          )
          if (filterData.length != invoiceData.length) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }

    default:
      return false
    // field['required'] = false;
  }
}
function validateSaveInvoiceHeader(dispJson, invoiceData, stateData, returnKeys=[]) {
  let validateFields = []
  dispJson.map((item, index) => {
    if (item.type == "Accordion") {
      if (item.displayRoles && item.displayRoles.length == 0) {
        item["hide"] = false
      } else if (item.displayRoles && item.displayRoles.length > 0) {
        let filteredRolesPanel = invoiceData?.assignmentDetails.filter(ele =>
          item.displayRoles.includes(ele.stageName)
        )
        if (filteredRolesPanel && filteredRolesPanel.length > 0) {
          item["hide"] = false
        } else {
          item["hide"] = true
        }
      } else {
        item["hide"] = false
      }
      if (item.enableRule && item.enableRule == true) {
        let accRules = item.rule
        let ruleType = item.ruleType ? item.ruleType : "OR"
        let ruleResults = []
        for (let i = 0; i < accRules.length; i++) {
          if (accRules[i].ruleType != undefined) {
            let accChildRuleType = accRules[i].ruleType
            let accChildRuleResults = []
            for (let j = 0; j < accRules[i].rules.length; j++) {
              let updatedInvoiceData = {}
              if (
                accRules[i].rules[j] &&
                accRules[i].rules[j].referenceSource != undefined &&
                accRules[i].rules[j].referenceSource != "" &&
                accRules[i].rules[j].referenceSource != null
              ) {
                updatedInvoiceData =
                  stateData[accRules[i].rules[j].referenceSource]
              } else {
                updatedInvoiceData = invoiceData
              }
              let accChildRuleResp = checkRule(
                accRules[i].rules[j],
                updatedInvoiceData
              )
              accChildRuleResults.push(accChildRuleResp)
            }
            if (accChildRuleType == "OR") {
              let accFilterdRes = accChildRuleResults.filter(
                ele => ele === false
              )
              if (accFilterdRes.length > 0) {
                ruleResults.push(false)
              } else {
                ruleResults.push(true)
              }
            } else if (accChildRuleType == "AND") {
              let accFilterdRes = accChildRuleResults.filter(
                ele => ele === true
              )
              if (accFilterdRes.length > 0) {
                ruleResults.push(true)
              } else {
                ruleResults.push(false)
              }
            }
          } else {
            let updatedInvoiceData = {}
            if (
              accRules[i] &&
              accRules[i].referenceSource != undefined &&
              accRules[i].referenceSource != "" &&
              accRules[i].referenceSource != null
            ) {
              updatedInvoiceData = stateData[accRules[i].referenceSource]
            } else {
              updatedInvoiceData = invoiceData
            }
            let ruleResp = checkRule(accRules[i], updatedInvoiceData)
            ruleResults.push(ruleResp)
          }
        }

        if (ruleType == "OR") {
          let filterdRes = ruleResults.filter(ele => ele === false)
          if (filterdRes.length > 0) {
            item["hide"] = false
          } else {
            item["hide"] = true
          }
        } else if (ruleType == "AND") {
          let filterdRes = ruleResults.filter(ele => ele === true)
          if (filterdRes.length > 0) {
            item["hide"] = true
          } else {
            item["hide"] = false
          }
        }
      }

      if (item["hide"] == false) {
        item.pannelFields.map(field => {
          if (
            field["type"] == "table-default-edit" ||
            field["type"] == "table"
          ) {
            field["required"] = false
          } else {
            if (field.key == "advanceToBeSettledHeader") {
              console.log("advanceToBeSettledHeader")
            }
            if (field.enableRule && field.enableRule == true) {
              let rules = field.rule
              let ruleType = field.ruleType ? field.ruleType : "OR"
              let ruleResults = []
              for (let i = 0; i < rules.length; i++) {
                let ruleAction = rules[i].ruleAction ? rules[i].ruleAction : 'hide';
                if (rules[i].ruleType != undefined) {
                  let childRuleType = rules[i].ruleType
                  let childRuleResults = []
                  for (let j = 0; j < rules[i].rules.length; j++) {
                    let updatedInvoiceData = {}
                    if (
                      rules[i].rules[j] &&
                      rules[i].rules[j].referenceSource != undefined &&
                      rules[i].rules[j].referenceSource != "" &&
                      rules[i].rules[j].referenceSource != null
                    ) {
                      updatedInvoiceData =
                        stateData[rules[i].rules[j].referenceSource]
                    } else {
                      updatedInvoiceData = invoiceData
                    }
                    let accChildRuleResp = checkRule(
                      rules[i].rules[j],
                      updatedInvoiceData
                    )
                    if (rules[i].rules[j]?.cond == "RO" || (rules[i].cond == 'forwardStatus' && ruleAction === "readOnly")) {
                      if (accChildRuleResp == false) {
                        field["type"] = "view"
                        field["required"] = false
                      }
                    } else if (ruleAction === "readOnly" && rules[i].cond != 'forwardStatus') {
                      if (accChildRuleResp === true) {
                        field["type"] = "view"
                        field["required"] = false

                      }
                    } else {
                      childRuleResults.push(accChildRuleResp)
                    }
                  }
                  if (childRuleResults.length > 0) {
                    if (childRuleType == "OR") {
                      let childFilterdRes = childRuleResults.filter(
                        ele => ele === false
                      )
                      if (childFilterdRes.length > 0) {
                        ruleResults.push(false)
                      } else {
                        ruleResults.push(true)
                      }
                    } else if (childRuleType == "AND") {
                      let childFilterdRes = childRuleResults.filter(
                        ele => ele === true
                      )
                      if (childFilterdRes.length > 0) {
                        ruleResults.push(true)
                      } else {
                        ruleResults.push(false)
                      }
                    }
                  }
                } else {
                  let updatedInvoiceData = {}
                  if (
                    rules[i] &&
                    rules[i].referenceSource != undefined &&
                    rules[i].referenceSource != "" &&
                    rules[i].referenceSource != null
                  ) {
                    updatedInvoiceData = stateData[rules[i].referenceSource]
                  } else {
                    updatedInvoiceData = invoiceData
                  }
                  let ruleResp = checkRule(rules[i], updatedInvoiceData)
                  if (rules[i].cond == "RO" || (rules[i].cond == 'forwardStatus' && ruleAction === "readOnly")) {
                    if (ruleResp == false) {
                      field["type"] = "view"
                      field["required"] = false
                    }
                  } else if (ruleAction === "readOnly" && rules[i].cond != 'forwardStatus') {
                    if (ruleResp === true) {
                      field["type"] = "view"
                      field["required"] = false

                    }
                  } else {
                    ruleResults.push(ruleResp)
                  }
                }
              }
              if (ruleResults.length > 0) {
                if (ruleType == "OR") {
                  let filterdRes = ruleResults.filter(ele => ele === false)
                  if (filterdRes.length > 0) {
                    field["hide"] = false
                    if (field["hide"] == false && field["required"] == false) {
                      field["required"] = false
                    } else {
                      field["required"] = true
                    }
                  } else {
                    field["hide"] = true
                    field["required"] = false
                  }
                } else if (ruleType == "AND") {
                  let filterdRes = ruleResults.filter(ele => ele === true)
                  if (filterdRes.length > 0) {
                    field["hide"] = true
                    field["required"] = false
                  } else {
                    field["hide"] = false
                    if (field["hide"] == false && field["required"] == false) {
                      field["required"] = false
                    } else {
                      field["required"] = true
                    }
                  }
                }
              }
            }

            if (field.enableReadOnlyRule && field.enableReadOnlyRule == true) {
              switch (field.readOnlyRule.cond) {
                case "RO":
                  let filteredRolesReadOnly =
                    invoiceData?.assignmentDetails.filter(ele =>
                      field.readOnlyRule.displayRoles.includes(ele.stageName)
                    )
                  if (
                    filteredRolesReadOnly &&
                    filteredRolesReadOnly.length > 0
                  ) {
                    field["type"] = field.readOnlyRule.fieldType
                    if (
                      field["type"] != "view" &&
                      field.required == true &&
                      field["hide"] == false
                    ) {
                      // validateFields.push(field.key)
                      field["required"] = true
                    } else {
                      field["required"] = false
                    }
                  }
              }
            }
            if (
              field.enableMandatoryRule &&
              field.enableMandatoryRule == true
            ) {
              switch (field.mandatoryRule.cond) {
                case "mandatory-roles":
                  let filteredRolesReadOnly =
                    this.props.invoiceHeaderData?.assignmentDetails.filter(
                      ele =>
                        field.mandatoryRule.displayRoles.includes(ele.stageName)
                    )
                  if (
                    filteredRolesReadOnly &&
                    filteredRolesReadOnly.length > 0
                  ) {
                    // field['required'] = field.mandatoryRule.required;
                    if (
                      field.mandatoryRule.required == true &&
                      field["hide"] == false
                    ) {
                      // validateFields.push(field.key)
                      field["required"] = true
                    } else {
                      field["required"] = false
                    }
                  }
              }
            }
            if (field.displayRoles && field.displayRoles.length == 0) {
              field["hide"] = false
            } else if (field.displayRoles && field.displayRoles.length > 0) {
              let filteredRoles = invoiceData?.assignmentDetails.filter(ele =>
                field.displayRoles.includes(ele.stageName)
              )
              if (filteredRoles && filteredRoles.length > 0) {
                field["hide"] = false
                field["required"] = false
                if (field["hide"] == false && field.required == true) {
                  // validateFields.push(field.key);
                  field["required"] = true
                } else {
                  field["required"] = false
                }
              } else {
                field["hide"] = true
                if (field["hide"] == false && field.required == true) {
                  // validateFields.push(field.key)
                  field["required"] = true
                } else {
                  field["required"] = false
                }
              }
            }
          }

          if (field.regex && field.regex != "") {
            let reg = field.regex;
            if (field.regex == 'other') {
              reg = field?.otherRegex;
            }
            if (
              invoiceData[field.key] &&
              invoiceData[field.key] != undefined &&
              invoiceData[field.key] != null &&
              invoiceData[field.key] != ""
            ) {
              let regex = new RegExp(reg, "g")
              let result = regex.test(invoiceData[field.key])
              if (result == false) {

                validateFields.push(
                  field.label + " - " + field.errorMessage
                )
                returnKeys.push(field.key)
              }
            }
          }

          if (field["required"] == true) {
            if (
              invoiceData[field.key] == undefined ||
              invoiceData[field.key] === null
            ) {
              validateFields.push(
                "Please fill the mandatory fields like " + field.label
              )
              returnKeys.push(field.key)
            } else {
              let value = invoiceData[field.key];
              value = value.toString().trim();
              if (value == undefined ||
                value == null || value == "") {
                validateFields.push(
                  "Please fill the mandatory fields like " + field.label
                )
                returnKeys.push(field.key)
              }
              console.log("field.key", field.key, isNaN(invoiceData[field.key]), invoiceData[field.key])
              if (isNaN(invoiceData[field.key])) {
                value = value.trim();
                if (value == undefined ||
                  value == null || value == "") {
                  validateFields.push(
                    "Please fill the mandatory fields like " + field.label
                  )
                  returnKeys.push(field.key)
                }
              }
              if (field['type'] == 'checkbox') {
                if (invoiceData[field.key] === false) {
                  validateFields.push(
                    "Please fill the mandatory fields like " + field.label
                  )
                  returnKeys.push(field.key)
                }
              }
            }
          }

        })
      }
    } else {
      let field = item
      if (field["type"] == "table-default-edit" || field["type"] == "table") {
        field["required"] = false
      } else {
        if (field.enableRule && field.enableRule == true) {
          let rules = field.rule
          let ruleType = field.ruleType ? field.ruleType : "OR"
          let ruleResults = []
          for (let i = 0; i < rules.length; i++) {
            if (rules[i].ruleType != undefined) {
              let childRuleType = rules[i].ruleType
              let childRuleResults = []
              for (let j = 0; j < rules[i].rules.length; j++) {
                let updatedInvoiceData = {}
                if (
                  rules[i].rules[j] &&
                  rules[i].rules[j].referenceSource != undefined &&
                  rules[i].rules[j].referenceSource != "" &&
                  rules[i].rules[j].referenceSource != null
                ) {
                  updatedInvoiceData =
                    stateData[rules[i].rules[j].referenceSource]
                } else {
                  updatedInvoiceData = invoiceData
                }
                let accChildRuleResp = checkRule(
                  rules[i].rules[j],
                  updatedInvoiceData
                )
                if (rules[i].rules[j]?.cond == "RO") {
                  if (accChildRuleResp == false) {
                    field["type"] = "view"
                    field["required"] = false
                  }
                } else {
                  childRuleResults.push(accChildRuleResp)
                }
              }
              if (childRuleResults.length > 0) {
                if (childRuleType == "OR") {
                  let childFilterdRes = childRuleResults.filter(
                    ele => ele === false
                  )
                  if (childFilterdRes.length > 0) {
                    ruleResults.push(false)
                  } else {
                    ruleResults.push(true)
                  }
                } else if (childRuleType == "AND") {
                  let childFilterdRes = childRuleResults.filter(
                    ele => ele === true
                  )
                  if (childFilterdRes.length > 0) {
                    ruleResults.push(true)
                  } else {
                    ruleResults.push(false)
                  }
                }
              }
            } else {
              let updatedInvoiceData = {}
              if (
                rules[i] &&
                rules[i].referenceSource != undefined &&
                rules[i].referenceSource != "" &&
                rules[i].referenceSource != null
              ) {
                updatedInvoiceData = stateData[rules[i].referenceSource]
              } else {
                updatedInvoiceData = invoiceData
              }
              let ruleResp = checkRule(rules[i], updatedInvoiceData)
              if (rules[i].cond == "RO") {
                if (ruleResp == false) {
                  field["type"] = "view"
                  field["required"] = false
                }
              } else {
                ruleResults.push(ruleResp)
              }
            }
          }
          if (ruleResults.length > 0) {
            if (ruleType == "OR") {
              let filterdRes = ruleResults.filter(ele => ele === false)
              if (filterdRes.length > 0) {
                field["hide"] = false
                if (field["hide"] == false && field["required"] == false) {
                  field["required"] = false
                } else {
                  field["required"] = true
                }
              } else {
                field["hide"] = true
                field["required"] = false
              }
            } else if (ruleType == "AND") {
              let filterdRes = ruleResults.filter(ele => ele === true)
              if (filterdRes.length > 0) {
                field["hide"] = true
                field["required"] = false
              } else {
                field["hide"] = false
                if (field["hide"] == false && field["required"] == false) {
                  field["required"] = false
                } else {
                  field["required"] = true
                }
              }
            }
          }
        }

        if (field.enableReadOnlyRule && field.enableReadOnlyRule == true) {
          switch (field.readOnlyRule.cond) {
            case "RO":
              let filteredRolesReadOnly = invoiceData?.assignmentDetails.filter(
                ele => field.readOnlyRule.displayRoles.includes(ele.stageName)
              )
              if (filteredRolesReadOnly && filteredRolesReadOnly.length > 0) {
                field["type"] = field.readOnlyRule.fieldType
                if (
                  field["type"] != "view" &&
                  field.required == true &&
                  field["hide"] == false
                ) {
                  // validateFields.push(field.key)
                  field["required"] = true
                } else {
                  field["required"] = false
                }
              }
          }
        }
        if (field.enableMandatoryRule && field.enableMandatoryRule == true) {
          switch (field.mandatoryRule.cond) {
            case "mandatory-roles":
              let filteredRolesReadOnly =
                this.props.invoiceHeaderData?.assignmentDetails.filter(ele =>
                  field.mandatoryRule.displayRoles.includes(ele.stageName)
                )
              if (filteredRolesReadOnly && filteredRolesReadOnly.length > 0) {
                // field['required'] = field.mandatoryRule.required;
                if (
                  field.mandatoryRule.required == true &&
                  field["hide"] == false
                ) {
                  // validateFields.push(field.key)
                  field["required"] = true
                } else {
                  field["required"] = false
                }
              }
          }
        }
        if (field.displayRoles && field.displayRoles.length == 0) {
          field["hide"] = false
        } else if (field.displayRoles && field.displayRoles.length > 0) {
          let filteredRoles = invoiceData?.assignmentDetails.filter(ele =>
            field.displayRoles.includes(ele.stageName)
          )
          if (filteredRoles && filteredRoles.length > 0) {
            field["hide"] = false
            field["required"] = false
            if (field["hide"] == false && field.required == true) {
              // validateFields.push(field.key);
              field["required"] = true
            } else {
              field["required"] = false
            }
          } else {
            field["hide"] = true
            if (field["hide"] == false && field.required == true) {
              // validateFields.push(field.key)
              field["required"] = true
            } else {
              field["required"] = false
            }
          }
        }
      }
      if (field.regex && field.regex != "") {
        let reg = field.regex;
        if (field.regex == 'other') {
          reg = field?.otherRegex;
        }
        if (
          invoiceData[field.key] &&
          invoiceData[field.key] != undefined &&
          invoiceData[field.key] != null &&
          invoiceData[field.key] != ""
        ) {
          let regex = new RegExp(reg, "g")
          let result = regex.test(invoiceData[field.key])
          if (result == false) {

            validateFields.push(
              field.errorMessage
            )
            returnKeys.push(field.key)
          }
        }
      }

      if (field["required"] == true) {
        if (
          invoiceData[field.key] == undefined ||
          invoiceData[field.key] === null
        ) {
          validateFields.push(
            "Please fill the mandatory fields like " + field.label
          )
          returnKeys.push(field.key)
        } else {
          let value = invoiceData[field.key];
          value = value.toString().trim();
          if (value == undefined ||
            value == null || value == "") {
            validateFields.push(
              "Please fill the mandatory fields like " + field.label
            )
            returnKeys.push(field.key)
          }
          console.log("field.key", field.key, isNaN(invoiceData[field.key]), invoiceData[field.key])
          if (isNaN(invoiceData[field.key])) {
            value = value.trim();
            if (value == undefined ||
              value == null || value == "") {
              validateFields.push(
                "Please fill the mandatory fields like " + field.label
              )
             returnKeys.push(field.key)
            }
          }
          if (field['type'] == 'checkbox') {
            if (invoiceData[field.key] === false) {
              validateFields.push(
                "Please fill the mandatory fields like " + field.label
              )
            }
          }
        }
      }
    }
  })
  return validateFields
}
function convert(obj, key, result) {
  if (typeof obj !== "object") {
    result[key] = obj
    return result
  }
  const keys = Object.keys(obj)

  for (let i = 0; i < keys.length; i++) {
    let newKey
    if (isNaN(parseFloat(keys[i]))) {
      newKey = key ? key + "." + keys[i] : keys[i]
    } else {
      newKey = key ? key : keys[i]
    }

    convert(obj[keys[i]], newKey, result)
  }

  return result
}

function objectToUrl(obj) {
  // https://dev-ssc-api-v2.nimbles2p.com/inbox-task?
  // sortBy=id:ASC
  // &sortBy=inboxTaskCategoryId:DESC
  // &sortBy=companyCode:DESC
  // &sortBy=supplierName:DESC
  // &limit=20&page=1&search=1
  // &searchBy=inboxTaskCategoryId,id
  // &filter.inboxTaskCategoryId=$in:3,4,1
  // &filter.id=$eq:1

  let keys = Object.keys(obj)
  let url = "?"
  for (let i = 0; i < keys.length; i++) {
    if (i != 0) {
      url = url + "&"
    }

    if (Array.isArray(obj[keys[i]])) {
      if (obj[keys[i]].length > 0) {
        for (let k = 0; k < obj[keys[i]].length; k++) {
          switch (keys[i]) {
            case "filter":
              if (i != 0 && k != 0) {
                url = url + "&"
              }
              if (i == 0 && k != 0) {
                url = url + "&"
              }
              if (obj[keys[i]][k].operator != undefined) {
                url =
                  url +
                  `filter.${obj[keys[i]][k].key}=${obj[keys[i]][k].operator}:${obj[keys[i]][k].value
                  }`
              } else {
                url =
                  url + `filter.${obj[keys[i]][k].key}=${obj[keys[i]][k].value}`
              }
              break
              break
            default:
              if (i != 0 && k != 0) {
                url = url + "&"
              }
              if (i == 0 && k != 0) {
                url = url + "&"
              }
              url = url + `${keys[i]}=${obj[keys[i]][k]}`
              break
          }
        }
      }
    } else {
      url = url + `${keys[i]}=${obj[keys[i]]}`
    }
  }
  return url
}

function getQueryObject1(rawObj, data, paginationData, searchText) {
  let queryObjectUpdated = {}
  let keys = Object.keys(rawObj)
  for (let i = 0; i < keys.length; i++) {
    let keyName = keys[i]
    switch (keyName) {
      case "tableName":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["tableName"] = rawObj["tableName"]
        }
      case "searchBy":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["searchBy"] = rawObj["searchBy"]
        }
        break
      case "pagination":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["pagination"] = rawObj["pagination"]
        }
        break
      case "search":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          if (rawObj[keyName] == "SEARCHTEXT") {
            queryObjectUpdated["search"] = searchText
          } else if (
            data[rawObj[keyName]] &&
            data[rawObj[keyName]] != undefined &&
            data[rawObj[keyName]] != null
          ) {
            queryObjectUpdated["search"] = data[rawObj[keyName]]
          }
        }
        break
      case "sortBy":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          let sortArr = rawObj[keyName].split(":")
          let sort = ""
          if (sortArr.length > 0) {
            if (sortArr[0] == "SORT_COLUMN_NAME") {
              if (paginationData.sortField != undefined) {
                sort = sort + paginationData.sortField
              }
            } else {
              sort = sort + sortArr[0]
            }
            if (sortArr[1] == "ORDER") {
              if (paginationData.sortOrder != undefined) {
                let order =
                  paginationData.sortOrder == "ascend" ? "ASC" : "DESC"
                sort = sort + order
              }
            } else {
              sort = sort + sortArr[1]
            }
            queryObjectUpdated["sortBy"] = sort
          }
        }
        break
      case "limit":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          if (paginationData.pageSize != undefined) {
            queryObjectUpdated["limit"] = paginationData.pageSize
          } else {
            queryObjectUpdated["limit"] = 9999
          }
        }
        break
      case "page":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          if (paginationData.current != undefined) {
            queryObjectUpdated["page"] = paginationData.pageSize
          } else {
            queryObjectUpdated["page"] = 1
          }
        }
        break
      case "filter":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          let updatedFilter = []
          for (let j = 0; j < rawObj["filter"].length; j++) {
            let filter = rawObj["filter"][j]
            let upFilter = {}
            if (filter.key) {
              upFilter["key"] = filter.key
            }
            if (filter.value && filter.value == "SEARCHTEXT") {
              upFilter["value"] = searchText
            } else if (filter.value && filter.value != undefined) {
              upFilter["value"] = filter.value
            }
            if (filter.operator) {
              upFilter["operator"] = filter.operator
            }
            updatedFilter.push(upFilter)
          }
          queryObjectUpdated["filter"] = updatedFilter

        }
        break
    }
  }
  return queryObjectUpdated
}

function setLocalStorageByKey(key, data) {
  let updatedData = JSON.stringify(data)
  localStorage.setItem(key, updatedData)
}

function clearLocalStorageData() {
  localStorage.clear()
}

function getQueryVariable(variable) {
  if (typeof window !== "undefined") {
    let query = window.location.search.substring(1)
    let vars = query.split("&")
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=")
      if (pair[0] == variable) {
        let decodeUrl = decodeURIComponent(pair[1])
        return decodeUrl
      }
    }
    return false
  } else {
    return false
  }
}

function clearCookiesandLocalStorageData() {
  clearLocalStorageData()
  cookies.remove("data", {
    path: "/",
    expires: "Thu, 01 Jan 1970 00:00:01 GMT",
  })
  cookies.remove("token", {
    path: "/",
    expires: "Thu, 01 Jan 1970 00:00:01 GMT",
  })
  cookies.remove("login", {
    path: "/",
    expires: "Thu, 01 Jan 1970 00:00:01 GMT",
  })
  cookies.remove("userName", {
    path: "/",
    expires: "Thu, 01 Jan 1970 00:00:01 GMT",
  })
}
function getLocalStorageByKey(key) {
  if (typeof window !== "undefined") {
    let data = localStorage.getItem(key)
    if (data != "" && data != undefined && data != null) {
      try {
        let updatedData = JSON.parse(data);
        return updatedData;
      } catch (error) {
        return data;
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

function getRolesByKey(key) {
  if (typeof window !== "undefined") {
    let user = JSON.parse(localStorage.getItem('data'))
    if (!user || !user.roles) {
      console.log("User object not found in localStorage")
      return
    }
    const roles = user.roles
    let hasRole = roles.some(
      role => role.roleValue === key
    )
    return hasRole
  }
}

function getQueryObject(rawObj, data, paginationData, searchText) {
  let queryObjectUpdated = {}
  let keys = Object.keys(rawObj)
  for (let i = 0; i < keys.length; i++) {
    let keyName = keys[i]
    switch (keyName) {
      case "tableName":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["tableName"] = rawObj["tableName"]
        }
        break
      case "distinctColumnName":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["distinctColumnName"] =
            rawObj["distinctColumnName"]
        }
        break
      case "searchBy":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          queryObjectUpdated["searchBy"] = rawObj["searchBy"]
        }
        break
      case "search":
        if (
          rawObj[keyName] !== undefined &&
          rawObj[keyName] !== null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] !== ""
        ) {
          if (rawObj[keyName] == "SEARCHTEXT") {
            queryObjectUpdated["search"] = searchText
          } else if (
            data[rawObj[keyName]] !== undefined &&
            data[rawObj[keyName]] !== null
          ) {
            queryObjectUpdated["search"] = data[rawObj[keyName]]
          }
        }
        break
      case "sortBy":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          let sortArr = rawObj[keyName].split(":")
          let sort = ""
          if (sortArr.length > 0) {
            if (sortArr[0] == "SORT_COLUMN_NAME") {
              if (
                paginationData != undefined &&
                paginationData.sortField != undefined
              ) {
                sort = sort + paginationData.sortField
              }
            } else {
              sort = sort + sortArr[0]
            }
            if (sortArr[1] == "ORDER") {
              if (
                paginationData != undefined &&
                paginationData.sortOrder != undefined
              ) {
                let order =
                  paginationData.sortOrder == "ascend" ? "ASC" : "DESC"
                let colon = (typeof (sort) != "undefined") ? (":") : ''
                sort = sort + colon + order
              }
            } else {
              sort = sort + ":" + sortArr[1]
            }
            queryObjectUpdated["sortBy"] = sort
          }
        }
        break
      case "limit":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          if (
            paginationData != undefined &&
            Object.keys(paginationData).length !== 0
          ) {
            if (paginationData.pageSize !== undefined) {
              queryObjectUpdated["limit"] = paginationData.pageSize;
            } else {
              queryObjectUpdated["limit"] = 9999;
            }
          } else {
            queryObjectUpdated["limit"] = rawObj[keyName];
          }
        }
        break;

      case "page":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          if (
            paginationData != undefined &&
            paginationData.current != undefined
          ) {
            queryObjectUpdated["page"] = paginationData.current
          } else {
            queryObjectUpdated["page"] = 1
          }
        }
        break
      case "filter":
        if (
          rawObj[keyName] &&
          rawObj[keyName] != undefined &&
          rawObj[keyName] != null &&
          rawObj[keyName].length > 0 &&
          rawObj[keyName] != ""
        ) {
          let updatedFilter = []
          for (let j = 0; j < rawObj["filter"].length; j++) {
            let filter = rawObj["filter"][j]
            let upFilter = {}
            if (filter.key) {
              upFilter["key"] = filter.key
            }
            if (filter.value && filter.value == "SEARCHTEXT") {
              upFilter["value"] = searchText
            } else if (
              filter.value != undefined &&
              data[filter.value] != undefined
            ) {
              upFilter["value"] = data[filter.value]
            } else {
              let index = filter.value.indexOf("|");
              if (index != -1) {
                let valArr = filter.value.split('|');
                let value = ''
                for (let v = 0; v < valArr.length; v++) {
                  if (v != 0) {
                    value = value + ','
                  }
                  if (valArr[v] != undefined &&
                    data[valArr[v]] != undefined) {
                    value = value + data[valArr[v]]
                  } else {
                    if (valArr[v].includes('DATE_')) {
                      //Accepts DATE_CURRENT, DATE_MONTHS_2
                      let splitMonths = valArr[v].split('_');
                      if (splitMonths.length > 0 && splitMonths[1] == 'CURRENT') {
                        let currentDate = moment().format('YYYY-MM-DD');
                        value = value + currentDate;
                      } else if (splitMonths.length > 0 && splitMonths[1] == 'MONTHS') {
                        let date = moment().subtract(parseInt(splitMonths[2]), 'months').format('YYYY-MM-DD');
                        value = value + date;
                      }

                    } else {
                      value = value + valArr[v]
                    }
                  }
                }
              } else {
                let _index = -1;
                let val = filter.value;
                if (filter.value && filter.value != undefined) {
                  _index = filter.value.indexOf(".");
                }
                if (_index != -1) {
                  val = _.get(data, filter.value)
                }
                upFilter["value"] = val
              }
            }
            if (filter.operator) {
              upFilter["operator"] = filter.operator
            }
            updatedFilter.push(upFilter)
          }
          queryObjectUpdated["filter"] = updatedFilter
        }
        break
    }
  }
  return queryObjectUpdated
}
function checkIFFileIsValid(fileType, fileName) {
  let validFiles = [
    "image/jpeg",
    "application/pdf",
    "message/rfc822",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/msword",
    "application/zip",
    "text/plain",
    "image/png",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ]
  let rg1 = /^[^\\/:\*\?"@%=+!#&(,)${};<>\|]+$/ // forbidden characters \ / : * ? " < > |
  let rg2 = /^\./ // cannot start with dot (.)
  let rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i // forbidden file names
  let fileNameValid =
    rg1.test(fileName) && !rg2.test(fileName) && !rg3.test(fileName)
  let validFile = false
  if (validFile == false && fileType == "") {
    let nameSplit = fileName.split(".")
    if (nameSplit.length > 0 && nameSplit[nameSplit.length - 1] == "msg") {
      validFile = true
    }
  } else {
    validFile = validFiles.includes(fileType)
  }

  if (validFile == true) {
    if (fileNameValid == true) {
      return true
    } else {
      return 'File name should not contain special characters ^ ? < > |  / " : * $ { } , ; % # @ & !'
    }
  } else {
    return "Not a valid file"
  }
}

function readFileData(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      resolve(e.target.result)
    }
    reader.onerror = err => {
      reject(err)
    }
    reader.readAsDataURL(file)
  })
}



function encryptRequest(data, token) {
  let decryptedToken = jwt_decode(token)
  let upData = JSON.stringify(data)
  let encryptedData = cryptoJs.AES.encrypt(
    upData,
    decryptedToken.reqId
  ).toString()
  return encryptedData
}

function decryptRequest(data, token) {
  let decryptedToken = jwt_decode(token)
  let bytes = cryptoJs.AES.decrypt(data, decryptedToken.reqId)
  let originalText = bytes.toString(cryptoJs.enc.Utf8)
  return JSON.parse(originalText)
}

function getUserId(token) {
  let decryptedToken = jwt_decode(token)
  return decryptedToken || undefined
}

function hideRule(rule, invoiceData) {
  if (rule.displayRoles && rule.displayRoles.length > 0) {
    if (invoiceData && invoiceData.assignmentDetails) {
      let filteredRolesPanel = invoiceData.assignmentDetails.filter(ele =>
        rule.displayRoles.includes(ele.stageName)
      );
      if (filteredRolesPanel.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else if (rule.refKey && rule.value && invoiceData) {
    if (invoiceData[rule.refKey] === rule.value) {
      return false;
    } else {
      return true;
    }
  }
  else {
    return true;
  }
}

function ruleValidate(item, invoiceData, returnStateVariables) {
  let result = {
    enabled: false,
    readOnly: "notEnabled",
    hide: true
  };

  if (item && item.enableRule && item.enableRule == true) {
    let accRules = item.rule
    let ruleType = item.ruleType ? item.ruleType : "OR"
    let ruleResults = []
    for (let i = 0; i < accRules.length; i++) {
      if (accRules[i].ruleType != undefined) {
        let accChildRuleType = accRules[i].ruleType
        let accChildRuleResults = []
        for (let j = 0; j < accRules[i].rules.length; j++) {
          let upInvData = invoiceData
          if (
            accRules[i].rules[j] &&
            accRules[i].rules[j].referenceSource != undefined && accRules[i].rules[j].referenceSource != '' &&
            returnStateVariables != undefined
          ) {
            upInvData = returnStateVariables(
              accRules[i].rules[j].referenceSource
            )
          }
          let accChildRuleResp = checkRule(accRules[i].rules[j], upInvData)
          let ruleAction = accRules[i].ruleAction ? accRules[i].ruleAction : 'hide';
          if (ruleAction === "hide") {
            result.hide = hideRule(accRules[i].rules[j], upInvData);
          }
          if (ruleAction === "readOnly") {
            if (accRules[i].rules[j]?.cond == 'RO') {
              if (accChildRuleResp === false) {
                result.readOnly = 'enabled';
              }
            } else {
              if (accChildRuleResp === false) {
                result.readOnly = 'enabled';
              }
            }
          }
          accChildRuleResults.push(accChildRuleResp);
        }
        if (accChildRuleType == "OR") {
          let accFilterdRes = accChildRuleResults.filter(ele => ele === false)
          if (accFilterdRes.length > 0) {
            ruleResults.push(false)
          } else {
            ruleResults.push(true)
          }
        } else if (accChildRuleType == "AND") {
          let accFilterdRes = accChildRuleResults.filter(ele => ele === true)
          if (accFilterdRes.length > 0) {
            ruleResults.push(true)
          } else {
            ruleResults.push(false)
          }
        }
      } else {
        let ruleResp = checkRule(accRules[i], invoiceData)
        let ruleAction = accRules[i].ruleAction ? accRules[i].ruleAction : 'hide';
        if (ruleAction === "hide") {
          result.hide = hideRule(accRules[i], invoiceData);
        }
        if (ruleAction === "readOnly") {
          if (ruleResp === true) {
            result.readOnly = 'enabled';
          }
        }
        ruleResults.push(ruleResp);
      }
    }

    if (ruleType == "OR") {
      let filterdRes = ruleResults.filter(ele => ele === false)
      if (filterdRes.length > 0) {
        result.enabled = false
      } else {
        result.enabled = true
      }
    } else if (ruleType == "AND") {
      let filterdRes = ruleResults.filter(ele => ele === true)
      if (filterdRes.length > 0) {
        result.enabled = true
      } else {
        result.enabled = false
      }
    }
  } else {
    result.enabled = false
  }
  return result;
}

function checkIfTaskIsAssignedToUser(invoiceData) {
  if (invoiceData != undefined && invoiceData.assignmentDetails != undefined) {
    let assignmentDetails = cloneDeep(invoiceData.assignmentDetails)
    let userId = getLocalStorageByKey("data")?.id
    let filteredUser = assignmentDetails.filter(ele => ele.userId == userId)
    if (filteredUser.length > 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

function returnNestedValue(key, data, defaultValue) {
  try {
    if (key != undefined && key != null) {
      let _index = key.indexOf(".")

      if (_index == -1) {
        if (
          data[key] != null &&
          data[key] != undefined &&
          data[key] != ""
        ) {
          return data[key]
        } else if (defaultValue) {
          return defaultValue
        } else {
          return ""
        }
      } else {
        return _.get(data, key)
      }
    } else {
      return ""
    }
  } catch (e) {
    console.log("Error", e)
    return ""
  }
}

function returnMaskedData(typeOfMasking, value) {
  switch (typeOfMasking) {
    case 'last_four_characters':
      let lastfourChars = '';
      let maskedValue = 'X';
      if (value) {
        lastfourChars = value.substring((value.length - 4), (value.length))
        maskedValue = maskedValue + maskedValue.repeat(value.length - 5)
      }
      let result = maskedValue.concat(lastfourChars)
      return (result);
      break;
    case 'first_four_characters':
      let firstfourChars = '';
      let first_maskedValue = 'X';
      if (value) {
        firstfourChars = value.substring(0, 4)
        first_maskedValue = first_maskedValue + first_maskedValue.repeat(value.length - 5)
      }
      let first_result = firstfourChars.concat(first_maskedValue)
      return (first_result);
      break;
    case 'all_characters':
      let all_maskedValue = 'X';
      if (value) {
        all_maskedValue = all_maskedValue + all_maskedValue.repeat(value.length)
      }
      return (all_maskedValue);
      break;
  }
}

function extractValues(data, reqArray, output) {
  for (const reqItem of reqArray) {
    const [property, outputKey] = reqItem.split(':');
    if (data !== undefined && data !== null) {
      if (data[outputKey] != undefined) {
        output[property] = data[outputKey];
      }
    }
  }

  for (const key in data) {
    if (typeof data[key] === 'object') {
      extractValues(data[key], reqArray, output);
    }
  }
}

function recursiveFuncRespAppend(data, req) {
  const result = {};
  extractValues(data, req, result);
  return result;
}

function retrieveInboxType(inboxTypeState, value) {
  switch (inboxTypeState) {
    case "invoiceInbox":
      return { key: 'invoiceHeaderId', tableName: 'InvoiceComments' };
    case "vendorInbox":
      return { key: 'vendorInvitationId', tableName: 'VendorInvitationComments' };
    case "mdmInbox":
      return { key: 'mdmHeaderId', tableName: 'MdmDetailsComments' };
    case "prToPoInbox":
      return { key: 'poHeaderId', tableName: 'PoMasterComments' };
    default:
      return { key: 'invoiceHeaderId', tableName: 'InvoiceComments' };
  }
}

const sortBySequence = (a, b) => (parseInt(a.sequence) || 0) - (parseInt(b.sequence) || 0);


function returnRequestBodyForMicroflow(data, requestBodyConfig) {
  let reqBdy = {}
  for (let i = 0; i < requestBodyConfig.length; i++) {
    let req = requestBodyConfig[i];
    if (req.requestedBodyType == 'object') {
      let replacedDataNotNone = returnReplacedDataForMicroflow(data, req.requestBodyMapJson);
      reqBdy[req.requestedBodyKey] = replacedDataNotNone
    } else if (req.requestedBodyType == "array") {
      let replacedDataNotNone = returnReplacedDataForMicroflow(data, req.requestBodyMapJson);
      reqBdy[req.requestedBodyKey] = [replacedDataNotNone]
    } else {
      let replacedData = returnReplacedDataForMicroflow(data, req.requestBodyMapJson);
      reqBdy = {
        ...reqBdy,
        ...replacedData
      }
    }
  }
  return reqBdy;
}

function returnReplacedDataForMicroflow(data, requestBodyMapJson) {
  let reqBdy = {}
  let token = cookies.get("token");

  for (let r = 0; r < requestBodyMapJson.length; r++) {
    if (requestBodyMapJson[r].responseKey && data && data[requestBodyMapJson[r].responseKey]) {
      reqBdy[requestBodyMapJson[r].updateKey] = data[requestBodyMapJson[r].responseKey]
    } else if (requestBodyMapJson[r].updateKey == 'NULL') {
      reqBdy[requestBodyMapJson[r].updateKey] = null
    } else if (requestBodyMapJson[r].updateKey == 'UNDEFINED') {
      reqBdy[requestBodyMapJson[r].updateKey] = undefined
    } else if (requestBodyMapJson[r].updateKey == 'access_token') {
      reqBdy[requestBodyMapJson[r].updateKey] = token
    } else {
      reqBdy[requestBodyMapJson[r].updateKey] = requestBodyMapJson[r].responseKey
    }
  }
  return reqBdy;
}

function checkRuleForButton(enableCheck, btnRole) {
  const user = JSON.parse(localStorage.getItem('data'));
  const roles = user?.roles || [];
  if (enableCheck) {
    if (btnRole) {
      const allowRoles = btnRole?.split(',');
      const btnInRole = roles.some(role => allowRoles.map(e => e.toLowerCase()).includes(role.roleValue.toLowerCase()));
      return btnInRole;
    } else {
      return false;
    }
  }
  else {
    return true;
  }
}

function setSessionStorageByKey(key, data) {
  let updatedData = JSON.stringify(data)
  sessionStorage.setItem(key, updatedData)
}

function getSessionStorageByKey(key) {
  if (typeof window !== "undefined") {
    let data = sessionStorage.getItem(key);
    if (data !== "" && data !== undefined && data !== null) {
      try {
        let updatedData = JSON.parse(data);
        return updatedData;
      } catch (error) {
        return data;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function clearSessionStorage() {
  sessionStorage.clear();
}

function scrollToField(key) {
  if (document !== undefined) {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.focus();
      element.style.outline = '1px solid #ff4d4f';
      const removeOutline = () => {
        element.style.outline = null;
        element.removeEventListener('input', removeOutline);
        element.removeEventListener('change', removeOutline);
        element.removeEventListener('keydown', removeOutline);
        element.removeEventListener('mouseout', removeOutline);
      };
      element.addEventListener('input', removeOutline);
      element.addEventListener('change', removeOutline);
      element.addEventListener('keydown', removeOutline);
      element.addEventListener('mouseout', removeOutline);
    }
  }
}