/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Cookies from "universal-cookie"
import { decryptRequest, getLocalStorageByKey } from "./src/shared-v2/utilites/common-utility";
import { navigate } from 'gatsby';
const cookies = new Cookies()

export { default as wrapRootElement } from './ReduxWrapper';




export const onRouteUpdate = async ({ location, prevLocation }) => {
    try {
        function isPathAndRoleAuthorized(menus, currentPath, userRoles) {
            // Helper function to sanitize paths by ensuring they have a consistent trailing slash
            function sanitizePath(path) {
                return path.endsWith("/") ? path.slice(0, -1) : path;
            }
        
            // Normalize the currentPath
            const sanitizedCurrentPath = sanitizePath(currentPath);
        
            // Helper function to check if user role matches the path's role
            function hasRole(role, userRoles) {
                return role === "all" || userRoles.some(userRole => userRole.roleValue === role);
            }
        
            // Check if the currentPath exists in the menu or submenu
            function findPathInMenu(menus, currentPath) {
                for (let menu of menus) {
                    const sanitizedMenuPath = sanitizePath(menu.path); // Normalize the menu path
        
                    if (sanitizedMenuPath === currentPath) {
                        return menu;
                    }
        
                    // Recursively search in the submenu
                    if (menu.subMenu && menu.subMenu.length > 0) {
                        const foundSubmenu = findPathInMenu(menu.subMenu, currentPath);
                        if (foundSubmenu) return foundSubmenu;
                    }
                }
                return null; // Path not found in any menu or submenu
            }
        
            // First, check if the path exists in the menus
            const matchingPath = findPathInMenu(menus, sanitizedCurrentPath);
        
            // If the path doesn't exist, return true (since it's not part of the menu)
            if (!matchingPath) {
                return true; // Path doesn't exist in the menu, so we consider it authorized
            }
        
            // If the path exists, check if the role matches
            return hasRole(matchingPath.role, userRoles);
        }
        
        console.log("New pathname:", location.pathname);
        console.log("Old pathname:", prevLocation ? prevLocation.pathname : null);

        let token = (await typeof window) !== "undefined" ? cookies.get("token") : "";
        if (token != undefined && token != "") {
            let menus = localStorage.getItem("mn");
            let userRoles = getLocalStorageByKey('data').roles;
            let de = decryptRequest(menus, token);
            // console.log(JSON.parse(de), userRoles)
            let smenus = JSON.parse(de) || [];
            const isAuthorized = isPathAndRoleAuthorized(smenus, location.pathname, userRoles);

            if (isAuthorized) {
                console.log("Matching menu found:", isAuthorized);
               
            } else {
                console.log("No matching menu found for the path.");
                navigate("/v2/modules/default-landing/")
            }

        }
    } catch (e) {
        console.log("Error in gatsby-browser.js", e)
    }
    // Perform actions on navigation change
};